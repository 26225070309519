import { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { Dashboard } from '../routes/dashboard/Dashboard';
import { DashboardLayout } from '../components/layouts/DashboardLayout';

const RequireAuth = ({ isValid }) => {
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }
  if (location.pathname === routes.DASHBOARD) {
    return <DashboardLayout />;
  }
  return <AdminLayout />;
};

RequireAuth.propTypes = {
  isValid: PropTypes.bool.isRequired
};

export const AdminRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route path={routes.DASHBOARD} element={<Dashboard />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
