import { Card } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ellipse from '../../assets/images/images_dashboard/Ellipse 1.svg';
import '../../assets/styles/SCSS/admin/card.scss';

const { Meta } = Card;

export const Widget = ({ widget }) => {
  const { image, title, link } = widget;
  return (
    <Link to={link}>
      <Card className="widget-card">
        <img alt="" src={ellipse} className="widget-ellipse" />
        <div className="widget-image-div">
          <img alt={title} src={image} className="widget-image" />
        </div>
        <Meta title={title} />
      </Card>
    </Link>
  );
};

Widget.propTypes = {
  widget: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string
  })
};

Widget.defaultProps = {
  widget: {
    image: '',
    title: '',
    link: ''
  }
};
