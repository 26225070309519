import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Modal, Spin, Typography } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

export const ExportButton = ({
  fileName,
  dataName,
  url,
  headers,
  formatter,
  populate,
  extraQuery
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);
  const { message } = useErrorMessage();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${url}?${extraQuery ? `${extraQuery}&` : ''}${
          populate ? `populate=${populate}` : ''
        }`
      });
      if (data.length) setDataCSV(formatter ? data.map(formatter) : data);
      else setDataCSV('Pas de donnée');
    } catch (e) {
      message(e);
      setDataCSV('Pas de donnée');
    }
  }, [url, formatter, dataName, headers, populate]);

  useEffect(() => {
    setDataCSV([]);
  }, [dataName, url, headers]);

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        open={visible}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          Nous préparons votre fichier. Merci de patienter.
        </Typography.Text>
      </Modal>
      <CSVLink
        style={{ color: 'inherit' }}
        asyncOnClick
        onClick={(e, done) => {
          if (!dataCSV.length) {
            setVisible(true);
            e.persist();
            e.preventDefault();
            fetchData().then(() => {
              setVisible(false);
              e.target.click();
              done(false);
            });
          } else done();
        }}
        filename={fileName}
        data={dataCSV}
        headers={(headers || []).map(({ label, key }) => ({
          label: t(`${dataName}.form.${label}`),
          key: key || label
        }))}
      >
        <DownloadOutlined style={{ fontSize: '14px', marginRight: 8 }} />
        {t(`buttons.export`)}
      </CSVLink>
    </>
  );
};

ExportButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  dataName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formatter: PropTypes.func,
  extraQuery: PropTypes.string,
  populate: PropTypes.string
};

ExportButton.defaultProps = {
  formatter: null,
  extraQuery: null,
  populate: null
};
