import {
  HomeOutlined,
  UserOutlined,
  BranchesOutlined,
  SettingOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  WORKFLOWS: <BranchesOutlined />,
  CONFIGURATIONS: <SettingOutlined />
};

export default navMenuLogos;
