import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/handleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/adminRoutes';

export const AdminNavMenu = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu
      style={{
        padding: '12px 0',
        background: 'var(--clientColor)'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes).map(([menuItem, p]) =>
        subRoutes[menuItem]
          ? {
              key: p,
              icon: navMenuLogos[menuItem],
              label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
              children: Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => ({
                  key: subPath,
                  disabled: subPath === '',
                  label: (
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  )
                })
              )
            }
          : {
              key: p,
              disabled: p === '',
              label: (
                <NavLink
                  to={`${p}${
                    pathSearches[menuItem] ? pathSearches[menuItem] : ''
                  }`}
                >
                  {navMenuLogos[menuItem]}
                  <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
                </NavLink>
              )
            }
      )}
    />
  );
};

AdminNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
