import { Col, Row } from 'antd';
import { ContentCustom } from '../../components';
import { widgets } from './utils';
import { Widget } from './Widget';

export const Dashboard = () => (
  <ContentCustom>
    <Row
      gutter={[16, 16]}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}
    >
      {widgets.map((widget) => (
        <Col
          span={2.7}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}
        >
          <Widget key={widget.title} widget={widget} />
        </Col>
      ))}
    </Row>
  </ContentCustom>
);
