export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};
