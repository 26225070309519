import React from 'react';

export const AddIcon = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 11.25V6H11.25V11.25H6V12.75H11.25V18H12.75V12.75H18V11.25H12.75Z"
        fill="currentColor"
      />
    </svg>
  </span>
);
