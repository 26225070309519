import direction from '../../assets/images/images_dashboard/img-frame.png';
import paye from '../../assets/images/images_dashboard/payfitblue 1.svg';
import planning from '../../assets/images/images_dashboard/logo1.svg';
import planner from '../../assets/images/images_dashboard/planning 1.png';
import slack from '../../assets/images/images_dashboard/Slack_Logo.png';
import turbo from '../../assets/images/images_dashboard/logo_turbo_transparent 1.png';
import isuite from '../../assets/images/images_dashboard/logo-isuite-expert-1000x_-90-scale-ffffff-wCopyright 1.png';
import cashpad from '../../assets/images/images_dashboard/Cashpad 1.svg';
import clyo from '../../assets/images/images_dashboard/clyo-logo-grey 1.png';
import laddition from '../../assets/images/images_dashboard/laddition_logo_footer 1.svg';
import edf from '../../assets/images/images_dashboard/Électricité_de_France_logo 1.png';
import pickingbeer from '../../assets/images/images_dashboard/logo-pickingbeer_icon_white 1.png';
import webshop from '../../assets/images/images_dashboard/webshop-logo-edited 1.png';
import untappd from '../../assets/images/images_dashboard/untappd-catw 1.png';
import sunday from '../../assets/images/images_dashboard/sunday_app 1.png';
import guestonline from '../../assets/images/images_dashboard/guestonline 1.png';
import ubereats from '../../assets/images/images_dashboard/uber-eats-logo 1.png';
import easybeer from '../../assets/images/images_dashboard/easybeer 1.png';
import moovago from '../../assets/images/images_dashboard/MOOVAGO_logobaseline-rvb 1.png';
import shipping from '../../assets/images/images_dashboard/logo_shipping_dark 1.png';
import cctv from '../../assets/images/images_dashboard/cctv 1.png';
import alarm from '../../assets/images/images_dashboard/alarm-system 1.png';
import areas from '../../assets/images/images_dashboard/logo_areas_assurances_vectoriel 1.svg';
import accident from '../../assets/images/images_dashboard/question 1.png';
import comite from '../../assets/images/images_dashboard/communication 1.png';
import exploitation from '../../assets/images/images_dashboard/img-frame (1).png';
import logistique from '../../assets/images/images_dashboard/img-frame (2).png';
import commercial from '../../assets/images/images_dashboard/img-frame (3).png';
import communication from '../../assets/images/images_dashboard/img-frame (4).png';
import juridique from '../../assets/images/images_dashboard/img-frame (5).png';
import rh from '../../assets/images/images_dashboard/img-frame (6).png';
import notes from '../../assets/images/images_dashboard/quote 1.png';
import achats from '../../assets/images/images_dashboard/recipient 1.png';
import evenements from '../../assets/images/images_dashboard/logo 2.png';
import drive from '../../assets/images/images_dashboard/Google_Drive-Logo.wine 1.svg';

export const widgets = [
  {
    image: direction,
    title: 'Direction et finance',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: paye,
    title: 'Fiches de paye',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: planning,
    title: 'Planning',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: planner,
    title: 'Planner',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: slack,
    title: 'Slack',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: turbo,
    title: 'Turbo',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: isuite,
    title: 'iSuite Expert',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: cashpad,
    title: 'Cashpad',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: clyo,
    title: 'Clyo',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: laddition,
    title: "L'Addition",
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: edf,
    title: 'EDF',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: pickingbeer,
    title: 'Picking Beers',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: webshop,
    title: 'Webshop',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: untappd,
    title: 'Untappd',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: sunday,
    title: 'Sunday',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: guestonline,
    title: 'Guestonline',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: ubereats,
    title: 'Uber eats',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: easybeer,
    title: 'EasyBeer',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: moovago,
    title: 'Moovago',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: shipping,
    title: 'Shippingbo',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: cctv,
    title: 'Caméras',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: alarm,
    title: 'Alarmes',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: areas,
    title: 'Mutuelle',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: accident,
    title: 'Signaler un accident',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: comite,
    title: "Comité d'entreprise",
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: exploitation,
    title: 'Exploitation',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: logistique,
    title: 'Logistique',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: commercial,
    title: 'Commercial',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: communication,
    title: 'Communication',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: juridique,
    title: 'Juridique',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: rh,
    title: 'Ressources humaines',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: notes,
    title: 'Notes internes',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: achats,
    title: 'Achats',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: evenements,
    title: 'Evènements',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  },
  {
    image: drive,
    title: 'Drive',
    link: 'https://www.linkedin.com/company/groupe-vbr/?originalSubdomain=fr'
  }
];
